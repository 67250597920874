import androidx.compose.runtime.*
import dev.petuska.kmdc.core.KMDCInternalAPI
import dev.petuska.kmdc.core.MDCSideEffect
import dev.petuska.kmdc.list.Divider
import dev.petuska.kmdc.list.item.MDCListItemScope
import dev.petuska.kmdc.list.item.Text
import dev.petuska.kmdc.select.MDCSelect
import dev.petuska.kmdc.select.MDCSelectType
import dev.petuska.kmdc.select.anchor.Anchor
import dev.petuska.kmdc.select.menu.MDCSelectMenuScope
import dev.petuska.kmdc.select.menu.Menu
import dev.petuska.kmdc.select.menu.SelectItem
import dev.petuska.kmdc.select.onChange
import dev.petuska.kmdc.top.app.bar.*
import dev.petuska.kmdc.typography.MDCCaption
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.HTMLLIElement
import ru.campusapp.organization.generated.api.client.apis.DefaultApi
import ru.campusapp.organization.generated.api.client.apis.ScheduleApi
import ru.campusapp.organization.generated.api.client.models.*

fun main() {
    renderComposable(rootElementId = "root") {
        Schedule()
    }
}

private val baseUrl = "https://api.campus.dev.dewish.ru"

@Composable
internal fun Schedule() {
    val httpClient: HttpClient = remember {
        HttpClient {
            defaultRequest {
                header(
                    HttpHeaders.Authorization,
                    "Bearer 8gXFSxY-d_yzthbVLlHVjJrXLHI9fY8TkxgFO_H8UOInMfBBvz3i9-WOXcAwQq9lk2mgQciVKPhACDWA"
                )
            }
            expectSuccess = true
        }
    }
    val scheduleApi: ScheduleApi = remember(httpClient) {
        ScheduleApi(
            httpClient = httpClient,
            basePath = baseUrl,
            json = Json.Default
        )
    }
    val defaultApi: DefaultApi = remember(httpClient) {
        DefaultApi(
            httpClient = httpClient,
            basePath = baseUrl,
            json = Json.Default
        )
    }


    val organizationId: MutableState<String?> = remember { mutableStateOf(null) }
    val entityId: MutableState<String?> = remember { mutableStateOf(null) }

    LaunchedEffect(organizationId.value) {
        entityId.value = null
    }

    val startDate: MutableState<String> = remember { mutableStateOf("2024-05-13") }
    val endDate: MutableState<String> = remember { mutableStateOf("2024-05-19") }

    val schedule: RemoteState<EntitySchedule?> by produceState<RemoteState<EntitySchedule?>>(
        RemoteState.Loading,
        scheduleApi,
        startDate.value,
        endDate.value,
        entityId.value
    ) {
        val id: String? = entityId.value
        if (id == null) {
            this.value = RemoteState.Success(null)
        } else {
            try {
                val response: EntitySchedule = scheduleApi.getSchedulePost(
                    entityId = id,
                    scheduleBody = ScheduleBody(
                        from = startDate.value,
                        to = endDate.value
                    )
                )
                this.value = RemoteState.Success(response)
            } catch (exc: Throwable) {
                this.value = RemoteState.Error(exc)
            }
        }
    }

    MDCTopAppBar {
        TopAppBar {
            ScheduleSelector(
                defaultApi = defaultApi,
                organizationId = organizationId,
                entityId = entityId,
                startDate = startDate,
                endDate = endDate
            )
        }
        Main {
            RenderState(schedule) { scheduleData ->
                if (scheduleData != null) {
                    ScheduleContent(scheduleData)
                } else {
                    Div(
                        attrs = {
                            classes("cmp-centerd-wrapper")
                        }
                    ) {
                        Text("Schedule not selected")
                    }
                }
            }
        }
    }
}

@Composable
private fun MDCTopAppBarScope.ScheduleSelector(
    defaultApi: DefaultApi,
    organizationId: MutableState<String?>,
    entityId: MutableState<String?>,
    startDate: MutableState<String>,
    endDate: MutableState<String>,
) {
    Row {
        Section {
            Div(
                attrs = {
                    classes("cmp-title-wrapper")
                }
            ) {
                Title {
                    Text("Расписание")
                }
            }
            OrganizationSelector(
                defaultApi = defaultApi,
                organizationId = organizationId
            )
            EntitySelector(
                defaultApi = defaultApi,
                organizationId = organizationId,
                entityId = entityId
            )
        }
        Section(align = MDCTopAppBarSectionAlign.End) {
            DateInput(value = startDate.value) {
                style {
                    marginRight(8.px)
                }
                onInput { startDate.value = it.value }
            }

            DateInput(value = endDate.value) {
                onInput { endDate.value = it.value }
            }
        }
    }
}

@OptIn(KMDCInternalAPI::class)
@Composable
private fun OrganizationSelector(
    defaultApi: DefaultApi,
    organizationId: MutableState<String?>,
) {
    val organizations: RemoteState<List<CityOrganizations>> by produceState<RemoteState<List<CityOrganizations>>>(
        RemoteState.Loading,
        defaultApi
    ) {
        try {
            this.value = defaultApi.getOrganizations()
                .let { RemoteState.Success(it) }
        } catch (exc: Throwable) {
            this.value = RemoteState.Error(exc)
        }
    }

    MDCSelect(
        type = MDCSelectType.Filled,
        attrs = {
            classes("cmp-organization-selector")
            onChange { organizationId.value = it.detail.value }
        }
    ) {
        Anchor("Organization")

        Menu {
            RenderState(organizations) { cities: List<CityOrganizations> ->
                println("write ${cities.size} cities list")
                cities.forEach { city ->
                    println("write ${city.organizations.size} organizations list")

                    MDCCaption(city.name) {
                        style { marginLeft(16.px) }
                    }

                    SelectItems(
                        items = city.organizations,
                        itemValue = { it.id },
                        itemSelected = { it.id == organizationId.value },
                        itemContent = { Text(it.name) }
                    )

                    Divider()
                }
            }
        }
    }
}

@OptIn(KMDCInternalAPI::class)
@Composable
private fun EntitySelector(
    defaultApi: DefaultApi,
    organizationId: State<String?>,
    entityId: MutableState<String?>,
) {
    val state: RemoteState<List<OrganizationEntity>?> by produceState<RemoteState<List<OrganizationEntity>?>>(
        RemoteState.Loading,
        defaultApi,
        organizationId.value
    ) {
        val id: String? = organizationId.value
        if (id == null) {
            this.value = RemoteState.Success(null)
        } else {
            try {
                this.value = defaultApi.getOrganizationEntities(organizationId = id, type = "Group")
                    .let { RemoteState.Success(it) }
            } catch (exc: Throwable) {
                this.value = RemoteState.Error(exc)
            }
        }
    }

    MDCSelect(
        attrs = {
            onChange {
                entityId.value = it.detail.value
            }
        }
    ) {
        Anchor("Entity")

        Menu {
            RenderState(state) { entities: List<OrganizationEntity>? ->
                println("write ${entities?.size} entities list")

                SelectItems(
                    items = entities.orEmpty(),
                    itemValue = { it.id },
                    itemSelected = { it.id == entityId.value },
                    itemContent = { Text(it.name) }
                )
            }
        }
    }
}

@Composable
private fun ScheduleContent(data: EntitySchedule) {
    Table(
        attrs = {
            classes("cmp-table")
        }
    ) {
        val intervals: List<String> = remember(data) {
            data.days
                .flatMap { it.intervals.orEmpty() }
                .map { it.start + " - " + it.end }
                .toSet()
                .sorted()
        }

        Tr { TableHeaderRow(data) }

        intervals.forEach { TableIntervalRow(it, data) }
    }
}

@Composable
private fun TableHeaderRow(data: EntitySchedule) {
    Th {
        P { Text(value = "Time") }
    }
    data.days.forEach { scheduleDay ->
        Th {
            P { Text(value = scheduleDay.date) }
            P { Text(value = scheduleDay.week ?: "<unknown week>") }
        }
    }
}

@Composable
private fun TableIntervalRow(
    interval: String,
    data: EntitySchedule
) {
    Tr {
        Td {
            Text(value = interval)
        }
        data.days.forEach { scheduleDay ->
            Td(
                attrs = {
                    style {
                        border {
                            width(1.px)
                            color(Color.black)
                            style(LineStyle.Solid)
                        }
                    }
                }
            ) {
                val dayInterval: ScheduleInterval = remember(interval, scheduleDay) {
                    scheduleDay.intervals
                        ?.singleOrNull { (it.start + " - " + it.end) == interval }
                } ?: return@Td

                dayInterval.lessons.forEach { ScheduleLesson(it) }
            }
        }
    }
}

@Composable
private fun ScheduleLesson(lesson: IntervalLesson) {
    LessonTextItem(text = lesson.subject)
    LessonTextItem(text = lesson.uiClassroom)
    LessonTextItem(text = lesson.uiType)
    LessonTextItem(text = lesson.uiBuildingName)
    LessonTextItem(text = lesson.uiBuildingAddress)
    LessonTextItem(text = lesson.uiBuildingCoordinates)
    LessonTextItem(text = lesson.uiGroups)
    LessonTextItem(text = lesson.uiTeachers)
    LessonTextItem(text = lesson.uiSubgroups)
    LessonTextItem(text = lesson.uiLinks)
    LessonTextItem(text = lesson.uiComment)
}

@Composable
private fun LessonTextItem(text: String) {
    P(
        attrs = {
            style {
                marginTop(4.px)
                marginBottom(0.em)
            }
        }
    ) {
        Text(value = text)
    }
}

/**
 * Сделана специальная функция чтобы вызывать 1 раз MDCSideEffect, а не сотню раз
 * при добавлении всех сущностей наших - это вызывало лаги на десятки секунд
 */
@OptIn(KMDCInternalAPI::class)
@Composable
private fun <T> MDCSelectMenuScope.SelectItems(
    items: List<T>,
    itemValue: (T) -> String,
    itemSelected: (T) -> Boolean,
    itemContent: @Composable MDCListItemScope<HTMLLIElement>.(T) -> Unit
) {
    items.forEach { item: T ->
        SelectItem(
            value = itemValue(item),
            selected = itemSelected(item)
        ) {
            itemContent(item)
        }
    }

    MDCSideEffect(items, itemValue, itemSelected, itemContent, onDispose = MDCSelect::layoutOptions) {
        layoutOptions()
    }
}

private val IntervalLesson.uiClassroom: String get() = classroom ?: "<no classroom>"
private val IntervalLesson.uiType: String get() = type ?: "<no type>"
private val IntervalLesson.uiBuildingName: String get() = building?.name ?: "<no building name>"
private val IntervalLesson.uiBuildingAddress: String get() = building?.address ?: "<no building address>"
private val IntervalLesson.uiBuildingCoordinates: String
    get() = building?.coordinate
        ?.let { "${it.latitude} , ${it.longitude}" }
        ?: "<no building coordinates>"
private val IntervalLesson.uiGroups: String
    get() = groups?.joinToString { it.name ?: "-" } ?: "<no groups>"
private val IntervalLesson.uiTeachers: String
    get() = teachers?.joinToString { it.name ?: "-" } ?: "<no teachers>"
private val IntervalLesson.uiSubgroups: String
    get() = subgroups?.joinToString() ?: "<no subgroups>"
private val IntervalLesson.uiLinks: String
    get() = links?.joinToString { "${it.title} - ${it.url}" } ?: "<no links>"
private val IntervalLesson.uiComment: String get() = comment ?: "<no comment>"